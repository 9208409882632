import React from "react";
import i18next from '../../language/i18n';
import Link from '@mui/material/Link';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { EditSelectField, EditDatePickerField, EditTextField, EditCheckbox } from '../../components/Share/EditComponents';
import ComponentInLine from '../../components/Share/ComponentInLine';
import Validation from '../../share/validation';
import {
    getCreditRequest,
    getCreditworthinessAnalysis,
    getContract,
    getPledgeAgreement,
    getBillStatement,
    getBillStatementHandover,
    //getCoDebtorBillStatement, 
    getPaymentOrders,
    getAdministrativeBlock,
    //getAdministrativeBlockGarantor, 
    getLoanPlacementStatement,
    getApprovalDecision,
    getCertificateOfObligations,
    getDebtRepaymentPlan,
    getDebtBalance,
    getDebtBalance2,
    getGuaranteeBillStatement,
    getGuaranteeAgreement,
    getSolidarGuaranteeAgreement,
    getSolidarCoDebtorAgreement,
    getArchiveFile,
    getI02OB,
    getConsent,
    getDetailsByAccount,
    getDetailsByAccountWithoutGrouping,
    getPartnerLoans,
    getDebtStatus
} from '../../actions/reportAction';

class LoanReports extends React.Component {
    constructor(props) {
        super(props); this.state = {
            showAll: false
        }
    }
    onSubmit = (formValues) => {
        let item = this.reports().find(el => el.value == formValues.reportTypeID);
        if (item != null && item != undefined) {
            if (item.showAll) {
                item.action({
                    loanID: this.props.loan.loan.id,
                    fromDate: formValues.fromDate,
                    toDate: formValues.toDate,
                    link: formValues.link,
                    isOffBalance: formValues.offBalance
                });
            }
            else if (formValues.reportTypeID == 20) {
                item.action({ loanID: this.props.loan.loan.id });
            }
            else if (formValues.reportTypeID == 21 ||formValues.reportTypeID == 24) {
                item.action({ loanID: this.props.loan.loan.id, date: formValues.today });
            }
            else {
                item.action(this.props.loan.loan.id);
            }
        }
    }
    reports = () => {
        return [
            { value: 1, text: i18next.t('CreditRequest'), action: this.props.getCreditRequest, showAll: false, isVisible: true },
            { value: 2, text: i18next.t('CreditworthinessAnalysis'), action: this.props.getCreditworthinessAnalysis, showAll: false, isVisible: true },
            { value: 3, text: i18next.t('Contract'), action: this.props.getContract, showAll: false, isVisible: true },
            { value: 4, text: i18next.t('PledgeAgreement'), action: this.props.getPledgeAgreement, showAll: false, isVisible: true },
            { value: 5, text: i18next.t('BillStatement'), action: this.props.getBillStatement, showAll: false, isVisible: true },
            { value: 23, text: i18next.t('BillStatementHandover'), action: this.props.getBillStatementHandover, showAll: false, isVisible: true },
            { value: 6, text: i18next.t('PaymentOrders'), action: this.props.getPaymentOrders, showAll: false, isVisible: true },
            { value: 7, text: i18next.t('AdministrativeBlock'), action: this.props.getAdministrativeBlock, showAll: false, isVisible: true },
            { value: 8, text: i18next.t('LoanPlacementStatement'), action: this.props.getLoanPlacementStatement, showAll: false, isVisible: true },
            { value: 9, text: i18next.t('ApprovalDecision'), action: this.props.getApprovalDecision, showAll: false, isVisible: true },
            { value: 10, text: i18next.t('CertificateOfObligations'), action: this.props.getCertificateOfObligations, showAll: false, isVisible: true },
            { value: 11, text: i18next.t('DebtRepaymentPlan'), action: this.props.getDebtRepaymentPlan, showAll: false, isVisible: true },
            { value: 12, text: i18next.t('DebtBalance'), action: this.props.getDebtBalance, showAll: false, isVisible: true },
            { value: 13, text: i18next.t('GuaranteeBillStatement'), action: this.props.getGuaranteeBillStatement, showAll: false, isVisible: true },
            { value: 14, text: i18next.t('GuaranteeAgreement'), action: this.props.getGuaranteeAgreement, showAll: false, isVisible: true },
            { value: 15, text: i18next.t('SolidarCoDebtorAgreement'), action: this.props.getSolidarCoDebtorAgreement, showAll: false, isVisible: true },
            { value: 16, text: i18next.t('SolidarGuaranteeAgreement'), action: this.props.getSolidarGuaranteeAgreement, showAll: false, isVisible: true },
            { value: 17, text: i18next.t('ArchiveFile'), action: this.props.getArchiveFile, showAll: false, isVisible: true },
            { value: 18, text: i18next.t('I02OB'), action: this.props.getI02OB, showAll: false, isVisible: true },
            { value: 19, text: i18next.t('DetailsPerAccount'), action: this.props.getDetailsByAccount, showAll: true, isVisible: true/*this.hasPermission(['Admin', 'ClientAdmin', 'CompanyAdmin', 'Accounting']) /Bo ekstra tab*/ },
            { value: 19, text: i18next.t('DetailsPerAccount'), action: this.props.getDetailsByAccountWithoutGrouping, showAll: true, isVisible: true/*this.hasPermission(['Admin', 'ClientAdmin', 'CompanyAdmin', 'Accounting']) /Bo ekstra tab  Bo Copy*/ },
            { value: 20, text: i18next.t('PartnerLoans'), action: this.props.getPartnerLoans, showAll: false, isVisible: true/*this.hasPermission(['Admin', 'ClientAdmin', 'CompanyAdmin', 'Accounting'])*/ },
            { value: 21, text: i18next.t('DebtStatus'), action: this.props.getDebtStatus, showAll: false, isVisible: true },
            { value: 22, text: i18next.t('Consent'), action: this.props.getConsent, showAll: false, isVisible: true },
            { value: 24, text: i18next.t('DebtBalance2'), action: this.props.getDebtBalance2, showAll: false, isVisible: true },
            

            
        ];
    }
    hasPermission = (roles) => {
        for(let i = 0; i<roles?.length; i++){
          if(this.props.authUser.roles?.indexOf(roles[i]) > -1){
            return true;
          }
        }
        return false;
      }
    render() {
        return (
            <div>
                <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Field
                        name='reportTypeID'
                        component={this.props.EditSelectField}
                        onChange={(e, child) => {
                            let report = this.reports().find(el => el.value == child);
                            this.setState({
                                currentValue: parseInt(child),
                                showAll: report?.showAll == true
                            })
                        }}
                        label='ReportType'
                        dataSource={this.reports().filter(el => el.isVisible == true)}
                        hasEmpty={false}
                    >
                    </Field>
                    {this.state.showAll == true ?
                        <ComponentInLine>
                            <Field
                                name='fromDate'
                                component={this.props.EditDatePickerField}
                                label='FromDate'
                            />
                            <Field
                                name='toDate'
                                component={this.props.EditDatePickerField}
                                label='ToDate'
                            />
                            <Field
                                name='link'
                                component={this.props.EditTextField}
                                label='Link'
                            />
                            <Field
                                name='offBalance'
                                component={this.props.EditCheckbox}
                                label='OffBalance'
                            />
                        </ComponentInLine>
                        : (this.state.currentValue == 21 || this.state.currentValue == 24 ? 
                            <Field
                                name='today'
                                component={this.props.EditDatePickerField}
                                label='FromDate'
                            /> : null)
                    }
                    <Box
                        m={1} //margin
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Button color="primary" variant="contained" type="Submit" startIcon={<PictureAsPdfIcon />}>
                            {i18next.t('Report')}
                        </Button>
                    </Box>
                </form>
            </div>
        );
    }
}

const validate = (formValues) => {
    const errors = {};
    if (!Validation.isSelected(formValues.reportTypeID)) {
        errors.reportType = 'Polje je obavezno!';
    }
    // if (!Validation.hasNonNegativeValue(formValues.fromAccount)) {
    //     errors.fromAccount = 'Polje je obavezno!';
    // }
    // if (!Validation.hasNonNegativeValue(formValues.toAccount)) {
    //     errors.toAccount = 'Polje je obavezno!';
    // }
    return errors;
}

const formWrapper = reduxForm({
    form: 'formLoanReports',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true,
    initialValues: {
        reportTypeID: '1',
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),        
        today: new Date()
    }
})(LoanReports);

const mapStateToProps = state => {
    return {
        loan: state.loanSelected,
        authUser: state.authUser
    };
}


export default connect(mapStateToProps,
    {
        getCreditRequest,
        getCreditworthinessAnalysis,
        getContract,
        getPledgeAgreement,
        getBillStatement,
        getBillStatementHandover,
        //getCoDebtorBillStatement,
        getPaymentOrders,
        getAdministrativeBlock,
        //getAdministrativeBlockGarantor,
        getLoanPlacementStatement,
        getApprovalDecision,
        getCertificateOfObligations,
        getDebtRepaymentPlan,
        getDebtBalance,
        getDebtBalance2,
        getGuaranteeBillStatement,
        getGuaranteeAgreement,
        getSolidarGuaranteeAgreement,
        getSolidarCoDebtorAgreement,
        getArchiveFile,
        getI02OB,
        getConsent,
        getDetailsByAccount,
        getDetailsByAccountWithoutGrouping,
        getPartnerLoans,
        getDebtStatus,
        EditSelectField,
        EditDatePickerField,
        EditTextField,
        EditCheckbox
    })(formWrapper);
