import React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, change } from "redux-form";
import { sendLoanToApproval } from '../../actions/loanAction';
import { EditSelectField, EditDatePickerField, EditDecimalNumberField, EditCheckbox, EditTextField, EditIntegerNumberField } from '../Share/EditComponents';
import TableLoanPartner from '../LoanPartner/TableLoanPartner';
import ComponentInLine from '../Share/ComponentInLine';
import CreateLoanPartner from '../LoanPartner/CreateLoanPartner';
import EditLoanPartner from '../LoanPartner/EditLoanPartner';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Validation from '../../share/validation';
import i18next from '../../language/i18n';

import { getSelectBoxBranches } from '../../actions/branchAction';
import { getSelectBoxPartners } from '../../actions/partnerAction';
import { getSelectBoxExtendedLoanTypes } from '../../actions/loanTypeAction';
import { getSelectBoxAssignees } from '../../actions/assigneeAction';
import { getSelectBoxPaymentWays } from '../../actions/paymentWayAction';
import { getSelectBoxBanks } from '../../actions/bankAction';
import { getSelectBoxLoanStatus } from '../../actions/loanStatusAction';
import { getSelectBoxLoanPurpose } from '../../actions/loanPurposeAction';
import { getSelectBoxBrokers } from '../../actions/brokerAction';
import { getSelectBoxWayOfKnowings } from '../../actions/wayOfKnowingAction';
import { getSelectBoxOpinionOfTheReferents } from '../../actions/opinionOfTheReferentAction';
import { getSelectBoxRiskLevel } from '../../actions/riskLevelAction';
import { getSelectBoxUsers } from '../../actions/userAction';
import { getSelectBoxSourceOfFinancings } from '../../actions/sourceOfFinancingAction';
import integerNormalization from '../Share/Normalization/integerNormalization';
import { showLoadingDataInProgress, hideLoadingDataInProgress } from "../../actions/progressBarAction";

class FormLoan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded01: true,
            expanded02: false,
            expanded03: false,
            lifeInsuranceAmountEnabled: false,
            policyEnabled: false,
            lboEnabled: false
        };
    }
    componentDidMount = () => {
        this.props.showLoadingDataInProgress();
        this.props.getSelectBoxBranches();
        this.props.getSelectBoxPartners();
        this.props.getSelectBoxAssignees();
        this.props.getSelectBoxPaymentWays();
        this.props.getSelectBoxBanks();
        this.props.getSelectBoxLoanStatus();
        this.props.getSelectBoxLoanPurpose();
        this.props.getSelectBoxBrokers();
        this.props.getSelectBoxWayOfKnowings();
        this.props.getSelectBoxOpinionOfTheReferents();
        this.props.getSelectBoxRiskLevel();
        this.props.getSelectBoxUsers();
        this.props.getSelectBoxSourceOfFinancings();
        this.props.hideLoadingDataInProgress();
    }

    handleClose = () => {
        //this.props.closeDialogLoan();
    }
    sendToApproval = () => {
        if (this.props.isNew !== true) {
            this.props.sendLoanToApproval(this.props.formValues.id);
        }

    }
    getSelectedLoanType = (id) => {
        return this.props.loanTypes.filter(el => el.id == id)[0];
    }
    loanTypeChange = (e, child) => {
        let loanData = this.getSelectedLoanType(child);
        if (loanData === undefined) {
            return;
        }
        this.props.change('requestedAmount', loanData.amountMin);
        this.setState(
            {
                lifeInsuranceAmountEnabled: loanData.hasLifeInsurance,
                policyEnabled: loanData.hasPolicy,
                pawnOnTheVehicleEnabled: loanData.hasPawnOnTheVehicle,
                lboEnabled: loanData.hasLBO
            });
        this.props.change('lifeInsuranceAmount', 0);

        this.props.change('loanTermMonths', loanData.loanTerm);
        this.props.change('costOfTheBill', loanData.approvalCostAmount * loanData.billNumber);

        let loanDate = new Date();
        loanDate.setHours(0, 0, 0, 0);

        let firstMonth = new Date(loanDate);
        firstMonth.setMonth(loanDate.getMonth() + loanData.firstMonthAdd);
        if (loanData.hasLBO && firstMonth.getDate() > 20) {
            firstMonth.setMonth(firstMonth.getMonth() + 1);
            firstMonth.setDate(10);
        }

        let interestBearingFrom = new Date(loanDate);
        if (loanData.firstMonthAdd === 1) {
            let tempDate = new Date(loanDate);
            tempDate.setMonth(tempDate.getMonth() + 1);
            if (tempDate < firstMonth) {
                interestBearingFrom = new Date(firstMonth);
                interestBearingFrom.setMonth(interestBearingFrom.getMonth() - 1);
            }
        }
        this.props.change('firstRepaymentDate', firstMonth);
        this.props.change('appDate', loanDate);
        this.props.change('loanDate', loanDate);
        this.props.change('interestBearingFrom', interestBearingFrom);
        let interestRate = this.calculateInterestRate(loanData.loanTerm, loanData);
        this.calculateTotalMonthRepayment(loanData.amountMin, loanData.loanTerm, interestRate);

        this.calculateApproval(loanData, loanData.amountMin, loanData.loanTerm);
    }
    calculateApproval = (loanData, requestedAmount, loanTermMonths) => {
        let approvalAmount = 0;
        if (loanData.approvalCostAmount > 0) {
            approvalAmount = loanData.approvalCostAmount;
        }
        else {
            let amount = requestedAmount * loanData.approvalCostPercent / 100;
            approvalAmount = loanTermMonths < loanData.appCostBearingAfterMonths ? 0 : (amount > loanData.approvalCostMin ? amount : loanData.approvalCostMin);
        }
        this.props.change('approvalCost', approvalAmount);
    }
    loanTermMonthsChange = (loanTermMonths) => {
        let loanData = this.getSelectedLoanType(this.props.formValues.loanTypeID);
        this.calculateInterestRate(loanTermMonths, loanData);
        this.calculateTotalMonthRepayment(this.props.formValues.requestedAmount, loanTermMonths, this.props.formValues.interestRate);
        this.calculateApproval(loanData, this.props.formValues.requestedAmount, loanTermMonths);
    }
    requestedAmountChange = (requestedAmount) => {
        let loanData = this.getSelectedLoanType(this.props.formValues.loanTypeID);
        this.calculateTotalMonthRepayment(requestedAmount, this.props.formValues.loanTermMonths, this.props.formValues.interestRate);
        this.calculateApproval(loanData, requestedAmount, this.props.formValues.loanTermMonths);
    }
    interestRateChange = (interestRate) => {
        let loanData = this.getSelectedLoanType(this.props.formValues.loanTypeID);
        this.calculateTotalMonthRepayment(this.props.formValues.requestedAmount, this.props.formValues.loanTermMonths, interestRate);
    }
    calculateInterestRate = (loanTermMonths, loanData) => {
        let interestRate = loanTermMonths > loanData.alternativeIRTermFrom ? loanData.alternativeIR : loanData.interestRate;
        this.props.change("interestRate", interestRate);
        return interestRate;
    }
    calculateTotalMonthRepayment = (requestedAmount, loanTermMonths, interestRate) => {
        let interest = interestRate / 1200;
        let totalMonthRepayment = interestRate === 0 ? requestedAmount / loanTermMonths : requestedAmount * interest / (1 - (Math.pow(1 / (1 + interest), loanTermMonths)));
        totalMonthRepayment = parseFloat(totalMonthRepayment.toFixed(2));
        this.props.change("totalMonthRepayment", totalMonthRepayment);
        this.props.change("effectiveInterestRate", null);
        return totalMonthRepayment;
    }
    isDisabled = () => {
        return this.props.initialValues?.loanStatusID !== 0;
    }
    isStartDateDisabled = () => {
        return this.props.initialValues?.loanStatusID >= 4; //za realizaciju
    }

    isUpdateButtonVisible = () => {
        return this.props.initialValues?.loanStatusID < 4; //za realizaciju
    }
    isSendToApprovalButtonVisible = () => {
        return this.props.initialValues?.loanStatusID === 0 && this.props.initialValues?.id > 0;
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (this.props.formValues?.loanStatusID == 0) {
            let number = 0;
            for (let i = 0; i < nextProps.loanPartners?.length; i++) {
                let c = nextProps.loanPartners[i].billNumber.split(';');
                if (c?.length > 0) {
                    number += c.length;
                }
            }
            this.props.change('costOfTheBill', number * 5);

            //
            //console.log(this.props.loanTypes.length, nextProps.loanTypes.length)
            if (this.props.loanTypes.length != nextProps.loanTypes.length) {                
                let loanData = nextProps.loanTypes.filter(el => el.id == this.props.formValues.loanTypeID)[0];
                if (loanData !== null && loanData !== undefined) {
                    this.setState(
                        {
                            lifeInsuranceAmountEnabled: loanData.hasLifeInsurance,
                            policyEnabled: loanData.hasPolicy,
                            pawnOnTheVehicleEnabled: loanData.hasPawnOnTheVehicle,
                            lboEnabled: loanData.hasLBO
                        });
                }
            }
        }
        return true;
    }
    render() {
        return (
            <div>
                <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                    <Accordion expanded={this.state.expanded01} onChange={() => { this.setState({ expanded01: !this.state.expanded01 }) }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            Kredit
                        </AccordionSummary>
                        <AccordionDetails>
                            <ComponentInLine>
                                <div>
                                    <Field
                                        name='appDate'
                                        component={this.props.EditDatePickerField}
                                        label='AppDate'
                                        disabled
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='approvalDate'
                                        component={this.props.EditDatePickerField}
                                        label='ApprovalDate'
                                        disabled
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='loanDate'
                                        component={this.props.EditDatePickerField}
                                        label='LoanDate'
                                        disabled
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='loanNumber'
                                        component={this.props.EditTextField}
                                        label='LoanNumber'
                                        disabled={true}
                                    />
                                </div>
                            </ComponentInLine>
                            <ComponentInLine>
                                <div>
                                    <Field
                                        name='branchID'
                                        component={this.props.EditSelectField}
                                        label='Branch'
                                        onChange={(e, child) => {
                                            this.props.getSelectBoxExtendedLoanTypes(child);
                                        }}
                                        disabled={this.isDisabled()}
                                        dataSource={this.props.branchesSelectBox}
                                    >
                                    </Field>
                                </div>
                                <div>
                                    <Field
                                        name='loanStatusID'
                                        component={this.props.EditSelectField}
                                        label='LoanStatus'
                                        disabled
                                        dataSource={this.props.loanStatusSelectBox}
                                    >
                                    </Field>
                                </div>
                                <div>
                                    <Field
                                        name='employeeUserID'
                                        component={this.props.EditSelectField}
                                        label='Employee'
                                        dataSource={this.props.usersSelectBox}
                                        disabled={this.isDisabled()}
                                    >
                                    </Field>
                                </div>
                                <div>
                                    <Field
                                        name='brokerID'
                                        component={this.props.EditSelectField}
                                        label='Broker'
                                        dataSource={this.props.brokersSelectBox}
                                        disabled={this.isDisabled()}
                                    >
                                    </Field>
                                </div>
                            </ComponentInLine>

                            <ComponentInLine>
                                <div>
                                    <Field
                                        name='loanTypeID'
                                        component={this.props.EditSelectField}
                                        label='LoanType'
                                        onChange={this.loanTypeChange}
                                        disabled={this.isDisabled()}
                                        dataSource={this.props.loanTypesSelectBoxExtended}
                                    >
                                    </Field>
                                </div>
                                <div>
                                    <Field
                                        name='requestedAmount'
                                        component={this.props.EditDecimalNumberField}
                                        label='RequestedAmount'
                                        onChange={(e) => this.requestedAmountChange(e.target.value)}
                                        disabled={this.isDisabled()}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='loanTermMonths'
                                        component={this.props.EditIntegerNumberField}
                                        label='LoanTermMonths'
                                        onChange={(e) => this.loanTermMonthsChange(e.target.value)}
                                        disabled={this.isDisabled()}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='totalMonthRepayment'
                                        component={this.props.EditDecimalNumberField}
                                        label='TotalMonthRepayment'
                                        disabled={this.isDisabled()}
                                    />
                                </div>
                            </ComponentInLine>

                            <ComponentInLine>
                                <div>
                                    <Field
                                        name='approvalCost'
                                        component={this.props.EditDecimalNumberField}
                                        label='ApprovalCost'
                                        disabled={this.isDisabled()}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='costOfTheBill'
                                        component={this.props.EditDecimalNumberField}
                                        label='CostOfTheBill'
                                        disabled={true/*this.isDisabled()*/}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='lifeInsuranceAmount'
                                        component={this.props.EditDecimalNumberField}
                                        label='LifeInsuranceAmount'
                                        disabled={!this.state.lifeInsuranceAmountEnabled || this.isDisabled()}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='costOfVerification'
                                        component={this.props.EditDecimalNumberField}
                                        label='CostOfVerification'
                                        disabled={this.isDisabled()}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='theCostOfNotarHypothec'
                                        component={this.props.EditDecimalNumberField}
                                        label='TheCostOfNotarHypothec'
                                        disabled={this.isDisabled()}
                                    />
                                </div>
                            </ComponentInLine>

                            {/* <ComponentInLine>
                                <div>
                                    <Field
                                        name='insurancePolicyAmount'
                                        component={this.props.EditDecimalNumberField}
                                        label='InsurancePolicyAmount'
                                        disabled={this.isDisabled()}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='policyID'
                                        component={this.props.EditTextField}
                                        label='Policy'
                                        disabled={!this.state.policyEnabled || this.isDisabled()}
                                    />
                                </div>
                            </ComponentInLine> */}
                            <ComponentInLine>
                                <div>
                                    <Field
                                        name='interestRate'
                                        component={this.props.EditDecimalNumberField}
                                        onChange={(e) => this.interestRateChange(e.target.value)}
                                        label='InterestRate'
                                        disabled={this.isDisabled()}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='effectiveInterestRate'
                                        component={this.props.EditDecimalNumberField}
                                        label='EffectiveInterestRate'
                                        disabled={true}
                                    />
                                </div>
                                {/* <div>
                                    <Field
                                        name='penaltyRate'
                                        component={this.props.EditDecimalNumberField}
                                        label='PenaltyRate'
                                        disabled={this.isDisabled()}
                                    />
                                </div> */}
                            </ComponentInLine>

                            <ComponentInLine>

                                <div>
                                    <Field
                                        name='firstRepaymentDate'
                                        component={this.props.EditDatePickerField}
                                        label='FirstRepaymentDate'
                                        disabled={this.isStartDateDisabled()}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='interestBearingFrom'
                                        component={this.props.EditDatePickerField}
                                        label='InterestBearingFrom'
                                        disabled={this.isStartDateDisabled()}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='gracePeriodMonths'
                                        component={this.props.EditIntegerNumberField}
                                        label='GracePeriodMonths'
                                        normalize={integerNormalization}
                                        disabled={this.isDisabled()}
                                    />
                                </div>
                            </ComponentInLine>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={this.state.expanded02} onChange={() => { this.setState({ expanded02: !this.state.expanded02 }) }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel2-header"
                        >
                            Klijent
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableLoanPartner
                                disabled={this.isDisabled()} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={this.state.expanded03} onChange={() => { this.setState({ expanded03: !this.state.expanded03 }) }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                        >
                            Ostale informacije
                        </AccordionSummary>
                        <AccordionDetails>
                            <ComponentInLine>
                                <div>
                                    <Field
                                        name='paymentWayID'
                                        component={this.props.EditSelectField}
                                        label='PaymentWay'
                                        disabled={this.isDisabled()}
                                        dataSource={this.props.paymentWaysSelectBox}
                                    >
                                    </Field>
                                </div>
                                <div>
                                    <Field
                                        name='assigneeID'
                                        component={this.props.EditSelectField}
                                        label='Assignee'
                                        disabled={this.isDisabled()}
                                        dataSource={this.props.assigneesSelectBox}
                                    >
                                    </Field>
                                </div>
                                <div>
                                    <Field
                                        name='wayOfKnowingID'
                                        component={this.props.EditSelectField}
                                        label='WayOfKnowing'
                                        disabled={this.isDisabled()}
                                        dataSource={this.props.wayOfKnowingsSelectBox}
                                    >
                                    </Field>
                                </div>
                                <div>
                                    <Field
                                        name='loanPurposeID'
                                        component={this.props.EditSelectField}
                                        label='LoanPurpose'
                                        disabled={this.isDisabled()}
                                        dataSource={this.props.loanPurposeSelectBox}
                                    >
                                    </Field>
                                </div>
                                
                                <div>
                                    <Field
                                        name='sourceOfFinancingID'
                                        component={this.props.EditSelectField}
                                        label='SourceOfFinancing'
                                        disabled={this.isDisabled()}
                                        dataSource={this.props.sourceOfFinancingsSelectBox}
                                    >
                                    </Field>
                                </div>
                                
                            </ComponentInLine> 
                            <div>
                                <Field
                                    name='loanPurposeDescription'
                                    component={this.props.EditTextField}
                                    label='LoanPurposeDescription'
                                    disabled={this.isDisabled()}
                                />
                            </div>
                            <div>
                                <Field
                                    name='remarks'
                                    component={this.props.EditTextField}
                                    label='Remarks'
                                    multiline 
                                    disabled={this.isDisabled()}
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    {/*  
      <div>
                    <Field
                        name='toPay'
                        component={this.props.EditCheckbox}
                        label='ToPay'
                    />
                </div>
      <div>
                <Field
                    name='revisionID'
                    component={this.props.EditIntegerNumberField}
                    label='RevisionID'
                />
            </div>
            
            <div>
                <Field
                    name='version'
                    component={this.props.EditIntegerNumberField}
                    label='Version'
                />
            </div>
            <div>
                <Field
                    name='referenceNumberTypeEnum'
                    component={this.props.EditIntegerNumberField}
                    label='ReferenceNumberTypeEnum'
                />
            </div>

            <div>
                <Field
                    name='loanAppID'
                    component={this.props.EditIntegerNumberField}
                    label='LoanApp'
                />
            </div>

            
            

            
            
           
            <div>
                <Field
                    name='lastRepaymentDate'
                    component={this.props.EditDatePickerField}
                    label='LastRepaymentDate'
                />
            </div>
            
            
            <div>
                <Field
                    name='commisssionPercent'
                    component={this.props.EditDecimalNumberField}
                    label='CommisssionPercent'
                />
            </div>
            <div>
                <Field
                    name='commissionAmount'
                    component={this.props.EditDecimalNumberField}
                    label='CommissionAmount'
                />
            </div>
           
            <div>
                <Field
                    name='collateralTypeID'
                    component={this.props.EditIntegerNumberField}
                    label='CollateralType'
                />
            </div>

            

            
            <div>
                <Field
                    name='approvalCostPercent'
                    component={this.props.EditDecimalNumberField}
                    label='ApprovalCostPercent'
                />
            </div>
            
            

            
           
            
            
           

            <div>
                <Field
                    name='bankID'
                    component ={this.props.EditSelectField}
                    label = 'Bank'
                    dataSource={this.props.banksSelectBox}
                    >
                </Field>
            </div>

            
            <div>
                <Field
                    name='loanSourceID'
                    component={this.props.EditIntegerNumberField}
                    label='LoanSource'
                />
            </div>

            <div>
                <Field
                    name='totalToRepay'
                    component={this.props.EditDecimalNumberField}
                    label='TotalToRepay'
                />
            </div>
            <div>
                <Field
                    name='repaid'
                    component={this.props.EditDecimalNumberField}
                    label='Repaid'
                />
            </div>
            <div>
                <Field
                    name='restructuringDate'
                    component={this.props.EditDatePickerField}
                    label='RestructuringDate'
                />
            </div>
            <div>
                <Field
                    name='amountRestructured'
                    component={this.props.EditDecimalNumberField}
                    label='AmountRestructured'
                />
            </div>
            
            <div>
                <Field
                    name='journalPageNumber'
                    component={this.props.EditIntegerNumberField}
                    label='JournalPageNumber'
                />
            </div>
            <div>
                <Field
                    name='restructuringJPNumber'
                    component={this.props.EditIntegerNumberField}
                    label='RestructuringJPNumber'
                />
            </div>
            
            

            
            
            <div>
                <Field
                    name='chargeMontlyFee'
                    component={this.props.EditCheckbox}
                    label='ChargeMontlyFee'
                />
            </div>
            
            <div>
                <Field
                    name='keepClassification'
                    component={this.props.EditCheckbox}
                    label='KeepClassification'
                />
            </div>
            
            <div>
                <Field
                    name='chkUnos'
                    component={this.props.EditCheckbox}
                    label='ChkUnos'
                />
            </div>
            <div>
                <Field
                    name='isDelivered'
                    component={this.props.EditCheckbox}
                    label='IsDelivered'
                />
            </div>
            <div>
                <Field
                    name='forSue'
                    component={this.props.EditCheckbox}
                    label='ForSue'
                />
            </div>
            
            <div>
                <Field
                    name='reerentEvaluation'
                    component={this.props.EditIntegerNumberField}
                    label='ReerentEvaluation'
                />
            </div>
            <div>
                <Field
                    name='realizationDate'
                    component={this.props.EditDatePickerField}
                    label='RealizationDate'
                />
            </div>
            <div>
                <Field
                    name='opinionOfTheReferentID'
                    component ={this.props.EditSelectField}
                    label = 'OpinionOfTheReferent'
                    dataSource={this.props.opinionOfTheReferentsSelectBox}
                    >
                </Field>
            </div>

            
            <div>
                <Field
                    name='moratoriumRequestDate'
                    component={this.props.EditDatePickerField}
                    label='MoratoriumRequestDate'
                />
            </div>
            <div>
                <Field
                    name='moratoriumStart'
                    component={this.props.EditDatePickerField}
                    label='MoratoriumStart'
                />
            </div>
            <div>
                <Field
                    name='moratoriumEnd'
                    component={this.props.EditDatePickerField}
                    label='MoratoriumEnd'
                />
            </div>
            */}
                    <DialogActions>
                        {/*<Button onClick={this.handleClose} color="secondary" variant="outlined">
                {i18next.t('Cancel')}
            </Button>*/}
                        {
                            this.isUpdateButtonVisible() ? (<Button color="primary" variant="contained" type="Submit">
                                {i18next.t('Submit')}
                            </Button>) : null
                        }
                        {
                            this.isSendToApprovalButtonVisible() ? (<Button onClick={this.sendToApproval} color="success" variant="contained">
                                {i18next.t('SendTtoTheApproval')}
                            </Button>) : null
                        }
                    </DialogActions>
                </form>
                <CreateLoanPartner />
                <EditLoanPartner />
            </div>
        );
    }
}

const validate = (formValues, sender) => {
    const errors = {};
    let selectedLoan = null;

    if (!Validation.isSelected(formValues.loanTypeID)) {
        errors.loanTypeID = 'Polje je obavezno!';
    }
    else {
        let temp = sender.loanTypes.filter(el => el.id == formValues.loanTypeID)[0];
        if (temp !== null && temp !== undefined) {
            selectedLoan = temp;
        }
    }
    if (!Validation.isSelected(formValues.branchID)) {
        errors.branchID = 'Polje je obavezno!';
    }
    if (!Validation.isSelected(formValues.paymentWayID)) {
        errors.paymentWayID = 'Polje je obavezno!';
    }
    
    // if(!formValues.loanAppID){
    //     errors.loanAppID = 'Polje je obavezno!';
    // }
    // if(!formValues.partnerID){
    //     errors.partnerID = 'Polje je obavezno!';
    // }
    if (!Validation.hasNonNegativeValue(formValues.loanTermMonths)) {
        errors.loanTermMonths = 'Polje je obavezno!';
    }
    if (!Validation.hasNonNegativeValue(formValues.requestedAmount)) {
        errors.requestedAmount = 'Polje je obavezno!';
    }
    else if (selectedLoan !== null) {
        if (formValues.requestedAmount < selectedLoan.amountMin) {
            errors.requestedAmount = `Traženi iznos ne može biti manje od ${selectedLoan.amountMin}.`;
        }
        else if (formValues.requestedAmount > selectedLoan.amountMax) {
            errors.requestedAmount = `Traženi iznos ne može biti veći od ${selectedLoan.amountMax}.`;
        }
    }
    if (!formValues.firstRepaymentDate) {
        errors.firstRepaymentDate = 'Polje je obavezno!';
    }
    // if(!formValues.lastRepaymentDate){
    //     errors.lastRepaymentDate = 'Polje je obavezno!';
    // }
    if (!Validation.hasNonNegativeValue(formValues.interestRate)) {
        errors.interestRate = 'Polje je obavezno!';
    }
    // if(!formValues.commisssionPercent){
    //     errors.commisssionPercent = 'Polje je obavezno!';
    // }
    // if(!formValues.commissionAmount){
    //     errors.commissionAmount = 'Polje je obavezno!';
    // }
    if (!Validation.hasNonNegativeValue(formValues.penaltyRate)) {
        errors.penaltyRate = 'Polje je obavezno!';
    }
    // if(!formValues.approvalCostPercent){
    //     errors.approvalCostPercent = 'Polje je obavezno!';
    // }
    if (!Validation.hasNonNegativeValue(formValues.approvalCost)) {
        errors.approvalCost = 'Polje je obavezno!';
    }
    if (!Validation.hasNonNegativeValue(formValues.costOfTheBill)) {
        errors.costOfTheBill = 'Polje je obavezno!';
    }
    // if(!formValues.administrativeBan){
    //     errors.administrativeBan = 'Polje je obavezno!';
    // }
    if (!Validation.hasNonNegativeValue(formValues.totalMonthRepayment)) {
        errors.totalMonthRepayment = 'Polje je obavezno!';
    }
    // if (!Validation.hasNonNegativeValue(formValues.effectiveInterestRate)) {
    //     errors.effectiveInterestRate = 'Polje je obavezno!';
    // }
    // if(!formValues.billNumber){
    //     errors.billNumber = 'Polje je obavezno!';
    // }
    // if(!formValues.totalToRepay){
    //     errors.totalToRepay = 'Polje je obavezno!';
    // }
    // if(!formValues.repaid){
    //     errors.repaid = 'Polje je obavezno!';
    // }
    // if(!formValues.amountRestructured){
    //     errors.amountRestructured = 'Polje je obavezno!';
    // }
    if (!Validation.isSelected(formValues.loanStatusID)) {
        errors.loanStatusID = 'Polje je obavezno!';
    }
    
    if (!Validation.isSelected(formValues.sourceOfFinancingID)) {
        errors.loanStatusID = 'Polje je obavezno!';
    }
    // if (selectedLoan !== null && selectedLoan.hasPolicy && !Validation.hasNonNegativeValue(formValues.insurancePolicyAmount)) {
    //     errors.insurancePolicyAmount = 'Polje je obavezno!';
    // }
    if (selectedLoan !== null && selectedLoan.hasPolicy && !Validation.hasValue(formValues.policyID)) {
        errors.policyID = 'Polje je obavezno!';
    }
    if (!formValues.lifeInsuranceAmount && formValues.lifeInsuranceAmount !== 0) {
        errors.lifeInsuranceAmount = 'Polje je obavezno!';
    }
    // if(!formValues.chargeMontlyFee){
    //     errors.chargeMontlyFee = 'Polje je obavezno!';
    // }
    // if(!formValues.toPay){
    //     errors.toPay = 'Polje je obavezno!';
    // }
    // if(!formValues.keepClassification){
    //     errors.keepClassification = 'Polje je obavezno!';
    // }
    // if(!formValues.chkUnos){
    //     errors.chkUnos = 'Polje je obavezno!';
    // }
    // if(!formValues.isDelivered){
    //     errors.isDelivered = 'Polje je obavezno!';
    // }
    // if(!formValues.forSue){
    //     errors.forSue = 'Polje je obavezno!';
    // }
    if (!Validation.hasNonNegativeValue(formValues.costOfVerification)) {
        errors.costOfVerification = 'Polje je obavezno!';
    }
    if (!Validation.hasNonNegativeValue(formValues.theCostOfNotarHypothec)) {
        errors.theCostOfNotarHypothec = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return {
        branchesSelectBox: Object.values(state.branchesSelectBox),
        //loanAppsSelectBox: Object.values(state.loanAppsSelectBox),
        partnersSelectBox: Object.values(state.partnersSelectBox),
        loanTypesSelectBoxExtended: Object.values(state.loanTypesSelectBoxExtended).map(el => { return { value: el.id, text: el.name } }),
        loanTypes: Object.values(state.loanTypesSelectBoxExtended),
        //collateralTypesSelectBox: Object.values(state.collateralTypesSelectBox),
        assigneesSelectBox: Object.values(state.assigneesSelectBox),
        paymentWaysSelectBox: Object.values(state.paymentWaysSelectBox),
        banksSelectBox: Object.values(state.banksSelectBox),
        //loanSourcesSelectBox: Object.values(state.loanSourcesSelectBox),
        loanStatusSelectBox: Object.values(state.loanStatusSelectBox),
        //policiesSelectBox: Object.values(state.policiesSelectBox),
        brokersSelectBox: Object.values(state.brokersSelectBox),
        usersSelectBox: Object.values(state.usersSelectBox),
        wayOfKnowingsSelectBox: Object.values(state.wayOfKnowingsSelectBox),
        opinionOfTheReferentsSelectBox: Object.values(state.opinionOfTheReferentsSelectBox),
        formValues: getFormValues('formLoan')(state),
        loanPartners: Object.values(state.loanPartners),
        loanPurposeSelectBox: Object.values(state.loanPurposeSelectBox),
        sourceOfFinancingsSelectBox: Object.values(state.sourceOfFinancingsSelectBox),
    }
}
const formWrapper = reduxForm({
    form: 'formLoan',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true
})(FormLoan);
export default connect(mapStateToProps, {
    sendLoanToApproval,
    EditSelectField,
    EditDatePickerField,
    EditDecimalNumberField,
    EditCheckbox,
    EditTextField,
    EditIntegerNumberField,

    getSelectBoxBranches,
    getSelectBoxPartners,
    getSelectBoxExtendedLoanTypes,
    getSelectBoxAssignees,
    getSelectBoxPaymentWays,
    getSelectBoxBanks,
    getSelectBoxLoanStatus,
    getSelectBoxLoanPurpose,
    getSelectBoxBrokers,
    getSelectBoxWayOfKnowings,
    getSelectBoxOpinionOfTheReferents,
    getSelectBoxRiskLevel,
    getSelectBoxUsers,
    getSelectBoxSourceOfFinancings,
    showLoadingDataInProgress,
    hideLoadingDataInProgress,
    change
})(formWrapper);