import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import i18next from '../../language/i18n';
import { getLoanHalcom } from "../../actions/loanAction";
import DateProvider from '../../share/dateProvider';
import { Field, reduxForm } from "redux-form";
import { getSelectBoxBranches } from '../../actions/branchAction';
import { getSelectBoxPartners } from '../../actions/partnerAction';
import { getSelectBoxLoanTypes } from '../../actions/loanTypeAction';
import { getSelectBoxLoanStatus } from '../../actions/loanStatusAction';
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import { getSelectBoxCompanyDetails } from "../../actions/companyDetailsAction";
import { getGetHalcom } from "../../actions/reportAction";
import { EditSelectField } from '../Share/EditComponents';


class TableLoanHalcom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      ids: [],
      companyDetailsID: null
    }
  }
  componentDidMount = async () => {
    await this.props.getSelectBoxCompanyDetails();
    await this.props.getSelectBoxBranches();
    await this.props.getSelectBoxPartners();
    await this.props.getSelectBoxLoanTypes();
    await this.props.getSelectBoxLoanStatus();
    //await this.props.getLoanHalcom();
  }
  branchName = (props) => {
    try {
      return this.props.branches.find(el => el.value === props.row.branchID)?.text;
    } catch (err) {
      if (props.value != undefined && props.value !== null) {
        return props.value;
      };
    }
  }

  partnerName = (props) => {
    try {
      return this.props.partners.find(el => el.value === props.row.partnerID)?.text;
    } catch (err) {
      if (props.value != undefined && props.value !== null) {
        return props.value;
      };
    }
  }
  loanTypeName = (props) => {
    try {
      return this.props.loanTypes.find(el => el.value === props.row.loanTypeID)?.text;
    } catch (err) {
      if (props.value != undefined && props.value !== null) {
        return props.value;
      };
    }
  }

  loanStatusName = (props) => {
    try {
      return this.props.loanStatus.find(el => el.value === props.row.loanStatusID)?.text;
    } catch (err) {
      if (props.value != undefined && props.value !== null) {
        return props.value;
      };
    }
  }
  onSubmit = (formValues) => {
    this.setState({ companyDetailsID: formValues.companyDetailsID })
    if (formValues.companyDetailsID !== undefined && formValues.companyDetailsID !== null) {
      this.props.getLoanHalcom({ companyDetailsID: formValues.companyDetailsID });
    }
  }
  getHalcom = () => {
    if (this.state.companyDetailsID != null && this.state.ids.length > 0) {
      this.props.getGetHalcom({
        companyDetailsID: this.state.companyDetailsID,
        ids: this.state.ids
      });
    }
  }
  columns = [
    {
      field: 'BranchID',
      headerName: i18next.t('Branch'),
      minWidth: 150, flex: 1,
      type: 'singleSelect',
      valueOptions: this.props.branches?.map(el => ({ value: el.value, label: el.text })),
      valueGetter: this.branchName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.branchName(cellParams1).localeCompare(this.branchName(cellParams2))
    },
    { field: 'loanNumber', headerName: i18next.t('LoanNumber'), minWidth: 150, flex: 1 },

    { field: 'appDate', headerName: i18next.t('AppDate'), minWidth: 150, flex: 1, type: 'date', valueGetter: DateProvider.GetDate },

    {
      field: 'PartnerID',
      headerName: i18next.t('Partner'),
      minWidth: 200, flex: 2,
      type: 'singleSelect',
      valueOptions: this.props.partners?.map(el => ({ value: el.value, label: el.text })),
      valueGetter: this.partnerName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.partnerName(cellParams1).localeCompare(this.partnerName(cellParams2))
    },

    {
      field: 'LoanTypeID',
      headerName: i18next.t('LoanType'),
      minWidth: 160,
      valueGetter: this.loanTypeName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.loanTypeName(cellParams1).localeCompare(this.loanTypeName(cellParams2)),
      type: 'singleSelect',
      valueOptions: this.props.loanTypes.map(el => ({ value: el.value, label: el.text }))
    },
    { field: 'requestedAmount', headerName: i18next.t('RequestedAmount'), minWidth: 150, flex: 1, type: 'number' },
    {
      field: 'loanStatusID',
      headerName: i18next.t('LoanStatus'),
      minWidth: 150,
      flex: 1,
      hide: this.props.Status !== null,
      valueGetter: this.loanStatusName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.loanStatusName(cellParams1).localeCompare(this.loanStatusName(cellParams2)),
      type: 'singleSelect',
      valueOptions: this.props.loanStatus.map(el => ({ value: el.value, label: el.text }))
    }
  ];

  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={5}

        >
          <div>
            <Box
              sx={{
                '& .MuiTextField-root': { m: 1, width: '300px' },
              }}>
              <form /*onSubmit={this.props.handleSubmit(this.onSubmit)}*/>
                <Field
                  onChange={(data) => {
                    setTimeout(() => this.props.handleSubmit(this.onSubmit)(), 1);
                  }}
                  name='companyDetailsID'
                  component={this.props.EditSelectField}
                  label='CompanyDetails'
                  dataSource={this.props.companyDetails}
                  style={{width:'300px'}}
                >
                </Field>

              </form>

            </Box>
          </div>
          <div>
            <div style={{ padding: '15px' }}>
              <Button color="primary" variant="contained" onClick={this.getHalcom} style={{ marginLeft: '20px' }}>
                {i18next.t('CreateHalcom')}
              </Button>
            </div>
          </div>
        </Stack>
        <DataGrid
          rows={this.props.loansHalcom}
          columns={this.columns}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={itm => this.setState({ ids: itm })}
        />
      </div>
    );
  }
}

const formWrapper = reduxForm({
  form: 'formLoanHalcomSearch',
  destroyOnUnmount: false,
  enableReinitialize: true
})(TableLoanHalcom);

const mapStateToProps = state => {
  return {
    loansHalcom: Object.values(state.loansHalcom),
    branches: Object.values(state.branchesSelectBox),
    partners: Object.values(state.partnersSelectBox),
    loanTypes: Object.values(state.loanTypesSelectBox),
    loanStatus: Object.values(state.loanStatusSelectBox),
    companyDetails: Object.values(state.companyDetailsSelectBox)
  };
}

export default connect(mapStateToProps, {
  getSelectBoxCompanyDetails,
  getLoanHalcom,
  getSelectBoxBranches,
  getSelectBoxPartners,
  getSelectBoxLoanTypes,
  getSelectBoxLoanStatus,
  showLoadingDataInProgress,
  EditSelectField,
  getGetHalcom
})(formWrapper);