export const showPDFFile = async (response) => {
    let isJsonBlob = response.data instanceof Blob && response.data.type === "application/json";
    if(isJsonBlob){
        let responseData = await (response?.data)?.text();
        if (typeof responseData === "string") {
            let responseObject = JSON.parse(responseData);
            if(responseObject.succeeded === false){
                alert(responseObject.message);
            }
        }  
    }
    else {
        const url = window.URL.createObjectURL(new Blob([response.data], {
            type: "application/pdf",
        }));
        window.open(url); 
    }
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', 'file.pdf'); //or any other extension
    // document.body.appendChild(link);
    // link.click();
}

export const showExcelFile = async (response) => {
    let isJsonBlob = response.data instanceof Blob && response.data.type === "application/json";
    if(isJsonBlob){
        let responseData = await (response?.data)?.text();
        if (typeof responseData === "string") {
            let responseObject = JSON.parse(responseData);
            if(responseObject.succeeded === false){
                alert(responseObject.message);
            }
        }  
    }
    else {
        const url = window.URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }));
        window.open(url); 
    }
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', 'file.pdf'); //or any other extension
    // document.body.appendChild(link);
    // link.click();
}

export const showZipFile = (response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.zip'); //or any other extension
    document.body.appendChild(link);
    link.click();
}

export const showTXTFile = (response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.txt'); //or any other extension
    document.body.appendChild(link);
    link.click();
}


export const showFile = (response, fileName) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
}
