import api from "../api/myApi";
import { 
    CREATE_LOAN,
    GET_ALL_LOAN,
    GET_ALL_BASE_LOAN_DATA,
    GET_LOAN,
    GET_LOANS_BY_PARTNER,
    GET_LOANS_BY_SEARCH,
    GET_ALL_SELECTBOX_LOAN,
    DELETE_LOAN,
    UPDATE_LOAN,
    UPDATE_LOAN_START_DATE,
    UPDATE_LOAN_MADE_ACTION,
    GET_LOAN_HALCOM,
    ADD_DIALOG_LOAN,
    EDIT_DIALOG_LOAN,
    CLOSE_DIALOG_LOAN,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    SHOW_LOADING_DATA_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";
import history from "../share/history";
import { getSelectBoxExtendedLoanTypes } from './loanTypeAction';

export const createLoan = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Loan/Create', formValues);    
    dispatch(responseHandler(response, CREATE_LOAN));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
    if(response.data?.succeeded){
        //browserHistory.push("/Loan/" + response.data.data.id);
        history.push("/Loan/" + response.data.data.loan.id);
        //dispatch(reset('formLoan'));
    }
}

export const getAllLoans = () => async (dispatch) => {
    const response = await api.get('/api/v1/Loan/GetAll');
    dispatch(responseHandler(response, GET_ALL_LOAN));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}
export const getAllBaseLoansData = () => async (dispatch) => {
    const response = await api.get('/api/v1/Loan/GetAllBaseLoansData');
    dispatch(responseHandler(response, GET_ALL_BASE_LOAN_DATA));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getPageLoans = (pageData) => async (dispatch) => {
    const response = await api.post('/api/v1/Loan/GetPage', pageData);
    let message = null;
    let result = -1;
    if(response.status === 200){
        if(response.data.succeeded) {
            message = {
                type: GET_ALL_LOAN,
                payload: response.data.data.data
            };
            result = response.data.data.numberOfRecords;
        }
        else{
            message = {
                type: "error",
                payload: response.data.Message
            }
        }
    }
    else{
        message = {
            type: "error",
            payload: response.statusText
        }
    }
    dispatch(message);
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
    return result;
}

export const getLoan = (id) => async (dispatch) => {
    dispatch({type: SHOW_LOADING_DATA_PROGRESS});    
    const response = await api.get('/api/v1/Loan/Get/' + id);
    
    if(response.data?.data?.loan?.branchID !== null && response.data?.data?.loan?.branchID !== undefined){
        getSelectBoxExtendedLoanTypes(response.data.data.loan.branchID)(dispatch);
    }
    console.log(responseHandler(response, GET_LOAN));
    dispatch(responseHandler(response, GET_LOAN));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getSelectBoxLoans = () => async (dispatch) => {
    const response = await api.get('/api/v1/Loan/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_LOAN));
}

export const updateLoan = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Loan/Update', formValues);
    dispatch(responseHandler(response, UPDATE_LOAN));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}


export const updateLoanStartDate = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Loan/UpdateLoanStartDate', formValues);
    dispatch(responseHandler(response, UPDATE_LOAN_START_DATE));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteLoan = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Loan/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, UPDATE_LOAN));
}
   //actions
export const sendLoanToApproval = (id, opinionOfTheReferentID) => async (dispatch) => {
    const response = await api.post('/api/v1/Loan/SendLoanToApproval', { ID: id, OpinionOfTheReferentID: opinionOfTheReferentID });
    dispatch(responseHandler(response, UPDATE_LOAN_MADE_ACTION));
}

export const approveLoan = (id, opinionOfTheReferentID) => async (dispatch) => {
    const response = await api.post('/api/v1/Loan/ApproveLoan', { ID: id, OpinionOfTheReferentID: opinionOfTheReferentID });
    dispatch(responseHandler(response, UPDATE_LOAN_MADE_ACTION));
}

export const refuseLoan = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Loan/RefuseLoan', { ID: id });
    dispatch(responseHandler(response, UPDATE_LOAN_MADE_ACTION));
}

export const sendLoanToRealization = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Loan/SendLoanToRealization', { ID: id });
    dispatch(responseHandler(response, UPDATE_LOAN_MADE_ACTION));
}

export const realizeLoan = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Loan/RealizeLoan', { ID: id });
    dispatch(responseHandler(response, UPDATE_LOAN_MADE_ACTION));
}

export const returnLoanToDraft = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Loan/ReturnLoanToDraft', { ID: id });
    dispatch(responseHandler(response, UPDATE_LOAN_MADE_ACTION));
}
export const addDialogLoan = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_LOAN,
        payload: null
    });
}

export const editDialogLoan = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_LOAN,
        payload: item
    });
}

export const closeDialogLoan = () => async (dispatch) => {
    dispatch(reset('formLoan'));
    dispatch({
        type: CLOSE_DIALOG_LOAN,
        payload: undefined
    });
}

export const getLoansByPartner = (partnerID) => async (dispatch) => {
    const response = await api.post('/api/v1/Loan/GetLoansByPartner', {partnerID: partnerID});
    dispatch(responseHandler(response, GET_LOANS_BY_PARTNER));
}

export const getLoansBySearch = (search) => async (dispatch) => {
    const response = await api.post('/api/v1/Loan/GetLoansBySearch', {search: search});
    dispatch(responseHandler(response, GET_LOANS_BY_SEARCH));
}

export const getLoanHalcom = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/Loan/GetAllLoansForHalcom', formValues);
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});    
    dispatch(responseHandler(response, GET_LOAN_HALCOM));
}