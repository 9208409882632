import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CustomToolbar from '../Share/Table/CustomToolbar';
import i18next from '../../language/i18n';
import { addDialogLoan, editDialogLoan, deleteLoan, getPageLoans } from "../../actions/loanAction";
import DateProvider from '../../share/dateProvider';
import history from "../../share/history";
import DeleteButton from '../Share/Button/DeleteButton';
import { getSelectBoxBranches } from '../../actions/branchAction';
//import { getSelectBoxLoanApps } from '../../actions/loanAppAction';
import { getSelectBoxPartners } from '../../actions/partnerAction';
import { getSelectBoxLoanTypes } from '../../actions/loanTypeAction';
//import { getSelectBoxCollateralTypes } from '../../actions/collateralTypeAction';
//import { getSelectBoxAssignees } from '../../actions/assigneeAction';
//import { getSelectBoxPaymentWays } from '../../actions/paymentWayAction';
//import { getSelectBoxBanks } from '../../actions/bankAction';
//import { getSelectBoxLoanSources } from '../../actions/loanSourceAction';
import { getSelectBoxLoanStatus } from '../../actions/loanStatusAction';
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
//import { getSelectBoxPolicies } from '../../actions/policyAction';
//import { getSelectBoxBrokers } from '../../actions/brokerAction';
//import { getSelectBoxWayOfKnowings } from '../../actions/wayOfKnowingAction';
//import { getSelectBoxOpinionOfTheReferents } from '../../actions/opinionOfTheReferentAction';
import { getPartnerLoans } from '../../actions/reportAction';
import { getSelectBoxUsers } from "../../actions/userAction";


class TableLoan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IsOpenConfirmDialog: false,
      ID: null,
      rowCountState: 1000,
      isLoading: false,
      tablePageData: {
        pageSize: 20,
        pageNumber: 0,
        filter: [],
        sortData: null
      }
    }
    /*if(props.columns !== undefined){
      for(let c in props.columns){
        this.columns.push(props.columns[c]);
      }
    }*/console.log(this.props.columns);
  }
  componentDidMount = async () => {
    await this.props.getSelectBoxBranches();
    //this.props.getSelectBoxLoanApps();
    await this.props.getSelectBoxPartners();
    await this.props.getSelectBoxLoanTypes();
    //this.props.getSelectBoxCollateralTypes();
    //this.props.getSelectBoxAssignees();
    //this.props.getSelectBoxPaymentWays();
    //this.props.getSelectBoxBanks();
    //this.props.getSelectBoxLoanSources();
    await this.props.getSelectBoxLoanStatus();
    //this.props.getSelectBoxPolicies();
    //this.props.getSelectBoxBrokers();
    //this.props.getSelectBoxWayOfKnowings();
    //this.props.getSelectBoxOpinionOfTheReferents();
    this.props.getSelectBoxUsers();
    this.getPage();
  }
  getPage = async () => {
    this.props.showLoadingDataInProgress();
    if (this.state.tablePageData.filter.length > 0 &&
      (this.state.tablePageData.filter[0].value === null || this.state.tablePageData.filter[0].value === undefined)) {
      return;
    }
    let numberOfRecords = await this.props.getPageLoans({ Status: this.props.Status, ...this.state.tablePageData });
    if (numberOfRecords !== -1) {
      this.setState({ rowCountState: numberOfRecords });
    }
  }
  branchName = (props) => {
    try {
      return this.props.branches.find(el => el.value === props.row.branchID)?.text;
    } catch (err) {
      if (props.value != undefined && props.value !== null) {
        return props.value;
      };
    }
  }

  partnerName = (props) => {
    try {
      return this.props.partners.find(el => el.value === props.row.partnerID)?.text;
    } catch (err) {
      if (props.value != undefined && props.value !== null) {
        return props.value;
      };
    }
  }
  userName = (props) => {
    return this.props.users.find(el => el.value === props.row.employeeUserID)?.text;
  }
  loanTypeName = (props) => {
    try {
      return this.props.loanTypes.find(el => el.value === props.row.loanTypeID)?.text;
    } catch (err) {
      if (props.value != undefined && props.value !== null) {
        return props.value;
      };
    }
  }

  loanStatusName = (props) => {
    try {
      return this.props.loanStatus.find(el => el.value === props.row.loanStatusID)?.text;
    } catch (err) {
      if (props.value != undefined && props.value !== null) {
        return props.value;
      };
    }
  }
  getPartnerLoans = (params) => {
    if (this.props.getPartnerCreditBoardReport != undefined) {
      this.props.getPartnerCreditBoardReport({
        partnerID: params.row.partnerID,
        loanID: params.row.id
      });
    }
    else {
      this.props.getPartnerLoans({
        partnerID: params.row.partnerID
      });
    }
  }
  openLoan = (params) => {
    history.push("/Loan/" + params.row.id);
  }
  columns = () => [
    {
      field: 'BranchID',
      headerName: i18next.t('Branch'),
      minWidth: 150, flex: 1,
      type: 'singleSelect',
      valueOptions: this.props.branches?.map(el => ({ value: el.value, label: el.text })),
      valueGetter: this.branchName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.branchName(cellParams1).localeCompare(this.branchName(cellParams2))
    },
    //{ field: 'loanNumber', headerName: i18next.t('LoanNumber'), minWidth: 150, flex: 1 },
    {
      field: 'loanNumber',
      headerName: i18next.t('LoanNumber'),
      minWidth: 125,
      flex: 1,
      renderCell: (params) => (
        <strong>
          <Link href={"#/Loan/" + params.row.id}
          >
            {params.row.loanNumber ?? i18next.t('Loan')}
          </Link>
        </strong>
      ),
    },

    { field: 'appDate', headerName: i18next.t('AppDate'), minWidth: 150, flex: 1, type: 'date', valueGetter: DateProvider.GetDate },
    {
      field: 'PartnerID',
      headerName: i18next.t('Partner'),
      minWidth: 250, flex: 2,
      type: 'singleSelect',
      valueOptions: this.props.partners?.map(el => ({ value: el.value, label: el.text })),
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.partnerName(cellParams1).localeCompare(this.partnerName(cellParams2)),
      renderCell: (params) => (
        this.props.Status == 1 ?
          <strong>
            <Link
              style={{ cursor: 'pointer' }}
              onClick={() => this.getPartnerLoans(params)}
            >
              {this.partnerName(params)}
            </Link>
          </strong> : this.partnerName(params)
      ),
    },

    {
      field: 'LoanTypeID',
      headerName: i18next.t('LoanType'),
      minWidth: 160,
      valueGetter: this.loanTypeName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.loanTypeName(cellParams1).localeCompare(this.loanTypeName(cellParams2)),
      type: 'singleSelect',
      valueOptions: this.props.loanTypes.map(el => ({ value: el.value, label: el.text }))
    },
    { field: 'requestedAmount', headerName: i18next.t('RequestedAmount'), minWidth: 150, flex: 1, type: 'number' },
    {
      field: 'loanStatusID',
      headerName: i18next.t('LoanStatus'),
      minWidth: 150,
      flex: 1,
      hide: this.props.Status !== null,
      valueGetter: this.loanStatusName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.loanStatusName(cellParams1).localeCompare(this.loanStatusName(cellParams2)),
      type: 'singleSelect',
      valueOptions: this.props.loanStatus.map(el => ({ value: el.value, label: el.text }))
    },
    {
      field: 'EmployeeUserID',
      headerName: i18next.t('Employee'),
      minWidth: 300,
      valueGetter: this.userName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.userName(cellParams1).localeCompare(this.userName(cellParams2)),
      type: 'singleSelect',
      valueOptions: this.props.users.map(el => ({ value: el.value, label: el.text }))
    },
    ...(this.props.columns ?? []),
    // {
    //   field: 'request',
    //   headerName: ' ',
    //   minWidth: 125,
    //   flex: 1,
    //   renderCell: (params) => (
    //     <strong>
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         size="small"
    //         style={{ marginLeft: 16 }}
    //         onClick={() => this.openLoan(params)}
    //       >
    //         {i18next.t('Request')}
    //       </Button>
    //     </strong>
    //   ),
    // },
    /*{ field: 'firstRepaymentDate', headerName: i18next.t('FirstRepaymentDate'), minWidth: 150, flex: 1 },
    { field: 'lastRepaymentDate', headerName: i18next.t('LastRepaymentDate'), minWidth: 150, flex: 1 },
    { field: 'interestRate', headerName: i18next.t('InterestRate'), minWidth: 150, flex: 1 },
    { field: 'insurancePolicyAmount', headerName: i18next.t('InsurancePolicyAmount'), minWidth: 150, flex: 1 },
    { field: 'commisssionPercent', headerName: i18next.t('CommisssionPercent'), minWidth: 150, flex: 1 },
    { field: 'commissionAmount', headerName: i18next.t('CommissionAmount'), minWidth: 150, flex: 1 },
    { field: 'penaltyRate', headerName: i18next.t('PenaltyRate'), minWidth: 150, flex: 1 },

    { field: 'collateralTypeID', headerName: i18next.t('CollateralType'), minWidth: 150, flex: 1 },
   

    {
      field: 'AssigneeID',
      headerName: i18next.t('Assignee'),
      width: 160,
      valueGetter: this.assigneeName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.assigneeName(cellParams1).localeCompare(this.assigneeName(cellParams2))
    },
    { field: 'approvalDate', headerName: i18next.t('ApprovalDate'), minWidth: 150, flex: 1 },
    { field: 'approvalCostPercent', headerName: i18next.t('ApprovalCostPercent'), minWidth: 150, flex: 1 },
    { field: 'approvalCost', headerName: i18next.t('ApprovalCost'), minWidth: 150, flex: 1 },
    { field: 'costOfTheBill', headerName: i18next.t('CostOfTheBill'), minWidth: 150, flex: 1 },
    { field: 'loanDate', headerName: i18next.t('LoanDate'), minWidth: 150, flex: 1 },
    { field: 'totalMonthRepayment', headerName: i18next.t('TotalMonthRepayment'), minWidth: 150, flex: 1 },
    { field: 'effectiveInterestRate', headerName: i18next.t('EffectiveInterestRate'), minWidth: 150, flex: 1 },

    {
      field: 'PaymentWayID',
      headerName: i18next.t('PaymentWay'),
      width: 160,
      valueGetter: this.paymentWayName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.paymentWayName(cellParams1).localeCompare(this.paymentWayName(cellParams2))
    },

    {
      field: 'BankID',
      headerName: i18next.t('Bank'),
      width: 160,
      valueGetter: this.bankName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.bankName(cellParams1).localeCompare(this.bankName(cellParams2))
    },
    { field: 'billNumber', headerName: i18next.t('BillNumber'), minWidth: 150, flex: 1 },

    {
      field: 'LoanSourceID',
      headerName: i18next.t('LoanSource'),
      width: 160,
      valueGetter: this.loanSourceName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.loanSourceName(cellParams1).localeCompare(this.loanSourceName(cellParams2))
    },
    { field: 'totalToRepay', headerName: i18next.t('TotalToRepay'), minWidth: 150, flex: 1 },
    { field: 'repaid', headerName: i18next.t('Repaid'), minWidth: 150, flex: 1 },
    { field: 'restructuringDate', headerName: i18next.t('RestructuringDate'), minWidth: 150, flex: 1 },
    { field: 'amountRestructured', headerName: i18next.t('AmountRestructured'), minWidth: 150, flex: 1 },

    
    
    { field: 'journalPageNumber', headerName: i18next.t('JournalPageNumber'), minWidth: 150, flex: 1 },
    { field: 'restructuringJPNumber', headerName: i18next.t('RestructuringJPNumber'), minWidth: 150, flex: 1 },
    { field: 'riskAssesment', headerName: i18next.t('RiskAssesment'), minWidth: 150, flex: 1 },
    { field: 'remarks', headerName: i18next.t('Remarks'), minWidth: 150, flex: 1 },
    { field: 'policyID', headerName: i18next.t('Policy'), minWidth: 150, flex: 1 },
    

    {
      field: 'BrokerID',
      headerName: i18next.t('Broker'),
      width: 160,
      valueGetter: this.brokerName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.brokerName(cellParams1).localeCompare(this.brokerName(cellParams2))
    },
    { field: 'lifeInsuranceAmount', headerName: i18next.t('LifeInsuranceAmount'), minWidth: 150, flex: 1 },
    { field: 'chargeMontlyFee', headerName: i18next.t('ChargeMontlyFee'), minWidth: 150, flex: 1 },

    {
      field: 'WayOfKnowingID',
      headerName: i18next.t('WayOfKnowing'),
      width: 160,
      valueGetter: this.wayOfKnowingName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.wayOfKnowingName(cellParams1).localeCompare(this.wayOfKnowingName(cellParams2))
    },
    { field: 'interestBearingFrom', headerName: i18next.t('InterestBearingFrom'), minWidth: 150, flex: 1 },
    { field: 'toPay', headerName: i18next.t('ToPay'), minWidth: 150, flex: 1 },
    { field: 'keepClassification', headerName: i18next.t('KeepClassification'), minWidth: 150, flex: 1 },
    { field: 'chkUnos', headerName: i18next.t('ChkUnos'), minWidth: 150, flex: 1 },
    { field: 'isDelivered', headerName: i18next.t('IsDelivered'), minWidth: 150, flex: 1 },
    { field: 'forSue', headerName: i18next.t('ForSue'), minWidth: 150, flex: 1 },
    { field: 'costOfVerification', headerName: i18next.t('CostOfVerification'), minWidth: 150, flex: 1 },
    { field: 'reerentEvaluation', headerName: i18next.t('ReerentEvaluation'), minWidth: 150, flex: 1 },
    { field: 'realizationDate', headerName: i18next.t('RealizationDate'), minWidth: 150, flex: 1 },

    {
      field: 'OpinionOfTheReferentID',
      headerName: i18next.t('OpinionOfTheReferent'),
      width: 160,
      valueGetter: this.opinionOfTheReferentName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.opinionOfTheReferentName(cellParams1).localeCompare(this.opinionOfTheReferentName(cellParams2))
    },
    { field: 'moratoriumRequestDate', headerName: i18next.t('MoratoriumRequestDate'), minWidth: 150, flex: 1 },
    { field: 'moratoriumStart', headerName: i18next.t('MoratoriumStart'), minWidth: 150, flex: 1 },
    { field: 'moratoriumEnd', headerName: i18next.t('MoratoriumEnd'), minWidth: 150, flex: 1 },
    { field: 'theCostOfNotarHypothec', headerName: i18next.t('TheCostOfNotarHypothec'), minWidth: 150, flex: 1 },
    { field: 'createDate', headerName: i18next.t('CreateDate'), minWidth: 150, flex: 1 },
    { field: 'editDate', headerName: i18next.t('EditDate'), minWidth: 150, flex: 1 },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
                  onClick={() => this.delete(params)}
                ></DeleteButton> 
        </strong>
      )
    }*/
  ];
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  onPageDataChanged = (data) => {
    this.setState({ tablePageData: data }, () => {
      this.getPage();
    });
  }
  getLoans = () => {
    if (this.props.Status === null) {
      return this.props.loans;
    }
    else {
      return this.props.loans.filter(el => el.loanStatusID === this.props.Status);
    }
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.getLoans()}
          columns={this.columns()}
          components={{
            Toolbar: CustomToolbar
          }}
          checkboxSelection
          disableSelectionOnClick


          rowCount={this.state.rowCountState}
          loading={this.state.isLoading}
          rowsPerPageOptions={[10, 25, 50]}
          pagination
          page={this.state.tablePageData.page}
          pageSize={this.state.tablePageData.pageSize}
          paginationMode="server"
          onPageChange={(page) => this.onPageDataChanged({ ...this.state.tablePageData, pageNumber: page })}
          onPageSizeChange={(pageSize) => this.onPageDataChanged({ ...this.state.tablePageData, pageSize })}

          filterMode="server"
          onFilterModelChange={(filter) => this.onPageDataChanged({
            ...this.state.tablePageData, filter: filter.items === undefined || filter.items.length === 0 ? [] : [
              {
                name: filter.items[0].columnField,
                operator: filter.items[0].operatorValue,
                value: filter.items[0].value?.toString()
              }]
          })}
          onSortModelChange={(sort) => this.onPageDataChanged({ ...this.state.tablePageData, sortData: sort[0] ?? null })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loans: Object.values(state.loans),
    branches: Object.values(state.branchesSelectBox),
    //loanApps: Object.values(state.loanAppsSelectBox), 
    partners: Object.values(state.partnersSelectBox),
    loanTypes: Object.values(state.loanTypesSelectBox),
    //collateralTypes: Object.values(state.collateralTypesSelectBox), 
    //assignees: Object.values(state.assigneesSelectBox), 
    //paymentWays: Object.values(state.paymentWaysSelectBox), 
    //banks: Object.values(state.banksSelectBox), 
    //loanSources: Object.values(state.loanSourcesSelectBox), 
    loanStatus: Object.values(state.loanStatusSelectBox),
    //policies: Object.values(state.policiesSelectBox), 
    //brokers: Object.values(state.brokersSelectBox), 
    //wayOfKnowings: Object.values(state.wayOfKnowingsSelectBox), 
    //opinionOfTheReferents: Object.values(state.opinionOfTheReferentsSelectBox),
    users: Object.values(state.usersSelectBox),
  };
}

export default connect(mapStateToProps, {
  addDialogLoan,
  editDialogLoan,
  deleteLoan,
  getPageLoans,
  getSelectBoxBranches,
  //getSelectBoxLoanApps, 
  getSelectBoxPartners,
  getSelectBoxLoanTypes,
  //getSelectBoxCollateralTypes, 
  //getSelectBoxAssignees, 
  //getSelectBoxPaymentWays, 
  //getSelectBoxBanks, 
  //getSelectBoxLoanSources, 
  getSelectBoxLoanStatus,
  //getSelectBoxPolicies, 
  //getSelectBoxBrokers, 
  //getSelectBoxWayOfKnowings, 
  //getSelectBoxOpinionOfTheReferents,
  showLoadingDataInProgress,
  getPartnerLoans,
  getSelectBoxUsers
})(TableLoan);