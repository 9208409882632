import React from "react";
import TableLoan from '../../components/Loan/TableLoan';

class AllLoansPage extends React.Component{
    render(){
        return(<TableLoan
            Status={null}
        />);
    }
}

export default AllLoansPage;